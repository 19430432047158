<template>
  <div class="col-xs-12 col-md-4 item-box">
    <div class="blog-items">
      <div class="item">
        <div class="thumb">
          <a href="">
            <img
              :src="post.img"
              alt="Thumbnail"
            />
            <div class="overlay-icon">
              <i class="fa fa-images"></i>
            </div>
          </a>
        </div>
        <div class="info">
          <h4>
            <a href="">{{ post.title }}</a>
          </h4>
          <div class="meta"></div>
          <p>
            {{ post.description_crop }}
          </p>
          <router-link class="btn btn-sm btn-theme effect read-more" :to="`/nota/${post.slug}`">Leer Nota</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
