<template>
  <div class="faq-area bg-image default-padding">
    <div class="container">
      <div class="row">
        <CalculadoraForm class="mb-8 mb-md-0" />
        <ListQuestion />
      </div>
    </div>
  </div>
</template>

<script>
import CalculadoraForm from "./CalculadoraForm.vue";
import ListQuestion from "./ListQuestion.vue";
export default {
  components: { CalculadoraForm, ListQuestion },
  mounted() {},
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>