<template>
  <div class="col-md-6 appoinment contact-form">
    <h2 class="st-title black">Calculadora de <u>Finiquito</u></h2>
    <form @submit.prevent="send">
      <div class="row">
        <div class="col-md-12 py-0">
          <div class="form-group">
            <label for="motivo"
              >¿Cuál es el motivo por el cual termina tu relación
              laboral?</label
            >
            <select
              ref="motivo"
              v-model.number="form.motivo"
              class="form-control"
              placeholder="¿Cuál situación se asemeja más a la tuya?"
            >
              <option value="" selected>-- Seleccione un motivo --</option>
              <option value="2">Despido</option>
              <option value="4">Renuncia con más de 15 años</option>
              <option value="5">Renuncia con menos de 15 años</option>
              <option value="8">Terminación de contrato</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 py-0">
          <div class="form-group">
            <input
              ref="name"
              v-model.trim="form.name"
              class="form-control"
              id="name"
              name="name"
              placeholder="¿Cuál es tu nombre completo?"
              type="text"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 py-0">
          <div class="form-group">
            <label for="date_ingreso" class=""
              >¿Cuál fue tu fecha de ingreso?</label
            >
            <input
              ref="date_ingreso"
              v-model.trim="form.date_ingreso"
              type="date"
              onkeydown="return false"
              class="form-control"
              id="date_ingreso"
            />
            <!-- <span class="alert-error"></span> -->
          </div>
          <div class="form-group">
            <label for="date_egreso" class=""
              >¿Cuál fue tu fecha de salida?</label
            >
            <input
              ref="date_egreso"
              v-model.trim="form.date_egreso"
              type="date"
              onkeydown="return false"
              class="form-control"
              id="date_egreso"
            />
            <!-- <span class="alert-error"></span> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 py-0">
          <div class="form-group">
            <input
              ref="salario_mensual"
              v-model.number="form.salario_mensual"
              type="number"
              step="any"
              class="form-control"
              id="salario_mensual"
              min="0"
              placeholder="¿Cuál fue tu último salario mensual?"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 py-0">
          <button type="submit">
            Calcular Mi Finiquito<i class="fa fa-paper-plane"></i>
          </button>
        </div>
      </div>
      <!-- Alert Message -->
      <!-- <div class="col-md-12 alert-notification">
                        <div id="message" class="alert-msg"></div>
                    </div> -->
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        motivo: "",
        name: "",
        date_ingreso: "",
        date_egreso: "",
        salario_mensual: null,
      },
    };
  },
  methods: {
    validateForm() {
      if (this.form.motivo <= 0) {
        alert("El motivo es requerido");
        this.$refs.motivo.focus();
        return false;
      }

      if (this.form.name.length <= 0) {
        alert("El nombre del trabajador es requerido");
        this.$refs.name.focus();
        return false;
      }

      if (this.form.date_ingreso.length <= 0) {
        alert("La fecha de ingreso al trabajo es requerido");
        this.$refs.date_ingreso.focus();
        return false;
      }
      if (this.form.date_egreso.length <= 0) {
        alert("La fecha de salida es requerido");
        this.$refs.date_egreso.focus();
        return false;
      }

      if (this.form.date_egreso < this.form.date_ingreso) {
        alert("La fecha de salida no puede ser menor a la fecha de ingreso");
        this.$refs.date_ingreso.focus();
        return false;
      }

      if (this.form.salario_mensual <= 0) {
        alert("El ultimo sueldo mensual es requerido");
        this.$refs.salario_mensual.focus();
        return false;
      }
      return true;
    },
    send() {
      if (!this.validateForm()) {
        return;
      } else {
        // console.log("validado");
        sessionStorage.setItem("data", JSON.stringify(this.form));
        this.$router.push({ name: "Calculator" });
      }
    },
  },
};
</script>

<style scoped>
.v-application .black {
  background-color: transparent !important;
  border-color: transparent !important;
}
</style>