<template>
  <div class="blog-area default-padding bg-gray">
    <div class="container">
      <div class="site-heading text-center">
        <div class="row justify-center">
          <div class="col-md-8">
            <h2 class="st-title">Noticias de<u> Interes</u></h2>
            <p>
              Able an hope of body. Any nay shyness article matters own removal
              nothing his forming. Gay own additions education satisfied the
              perpetual. If he cause manor happy. Without farther she exposed
              saw man led. Along on happy could cease green oh.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <BlogItemCard
          v-for="(post, index) in posts"
          :key="index"
          :post="post"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BlogItemCard from "./BlogItemCard.vue";
import {mapActions} from "vuex";
export default {
  components: { BlogItemCard },
  data() {
    return {
      posts: [],
    };
  },
  methods:{
    ...mapActions('blog', ['getRecent']),
    async getBanner(){
      this.posts = await this.getRecent()
    }
  },
  async created() {
    await this.getBanner()
  }
};
</script>

<style scoped>
</style>
