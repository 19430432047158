<template>
  <div>
    <BannerArea />

    <FaqArea />

    <Services />

    <HowItWork />

    <TeamMembers />

    <BlogArea />
  </div>
</template>

<script>
import BannerArea from "../components/home/BannerArea.vue";
import BlogArea from "../components/home/BlogArea.vue";
import FaqArea from "../components/home/FaqArea.vue";
import HowItWork from "../components/home/HowItWork.vue";
import Services from "../components/home/Services.vue";
import TeamMembers from "../components/home/TeamMembers.vue";

export default {
  components: {
    BlogArea,
    TeamMembers,
    HowItWork,
    Services,
    BannerArea,
    FaqArea,
  },
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Inicio",
      };
    },
  },
};
</script>