<template>
  <div class="team-members-area bg-image item-white default-padding">
    <div class="container">
      <div class="site-heading text-center">
        <div class="row justify-center">
          <div class="col-md-8">
            <h2 class="st-title">
              ¿Cuál es tu duda sobre <u>derecho laboral?</u>
            </h2>
            <p>
              Consulta nuestros planes y membresías empresariales. Somos un
              grupo de abogados y profesionales altamente especializados en el
              área laboral, con gran experiencia y con las credenciales
              necesarias para satisfacer los intereses de nuestros clientes.
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 team-member-info">
          <div class="team-carousel-items owl-carousel owl-theme">
            <div class="item" v-for="(item, index) in services" :key="index">
              <div class="row align-center">
                <div class="col-md-6">
                  <img
                    :src="require(`../../assets/img/services/${item.img_url}`)"
                    :alt="item.title"
                  />
                </div>
                <div class="col-md-6 info title">
                  <h3>{{ item.title }}</h3>
                  <h5>{{ item.subtitle }}</h5>
                  <p>
                    {{ item.text }}
                  </p>
                  <form class="contact-form">
                    <button
                      @click="goContact"
                      type="submit"
                      name="submit"
                      id="submit"
                    >
                      Necesito Asesoria<i class="fa fa-paper-plane"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    $(".team-carousel-items").owlCarousel({
      loop: false,

      nav: true,
      dots: false,
      // autoWidth: true,
      items: 1,
      navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>",
      ],
    });
  },
  data() {
    return {
      services: [
        {
          title: "Plan Classic",
          subtitle: "Started Plan",
          img_url: "plan_classic.jpg",
          text: "Calling nothing end fertile for venture way boy. Esteem spirit temper too say adieus who direct esteem. It esteems luckily mr or picture placing drawing no. Apartments frequently or motionless on reasonable projecting expression. Way mrs end gave tall walk fact bed.",
        },
        {
          title: "Plan Plantino",
          subtitle: "Advanced Plan",
          img_url: "plan_platino.jpg",
          text: "Calling nothing end fertile for venture way boy. Esteem spirit temper too say adieus who direct esteem. It esteems luckily mr or picture placing drawing no. Apartments frequently or motionless on reasonable projecting expression. Way mrs end gave tall walk fact bed.",
        },
        {
          title: "Plan Enterprise",
          subtitle: "Premium Plan",
          img_url: "plan_enterprise.jpg",
          text: "Calling nothing end fertile for venture way boy. Esteem spirit temper too say adieus who direct esteem. It esteems luckily mr or picture placing drawing no. Apartments frequently or motionless on reasonable projecting expression. Way mrs end gave tall walk fact bed.",
        },
      ],
    };
  },
  methods: {
    goContact() {
      this.$router.push({ name: "Contact" });
    },
  },
};
</script>

<style>
@media (max-width: 768px) {
  .owl-prev {
    display: none !important;
  }

  .owl-next {
    display: none !important;
  }
}
</style>