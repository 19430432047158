<template>
  <div class="col-md-6 faq-items">
    <!-- Start Accordion -->
    <div class="acd-items acd-arrow">
      <div class="panel-group symb" id="accordion">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              <a data-toggle="collapse" data-parent="#accordion" href="#ac1">
                ¿Por qué calcular tu finiquito?
              </a>
            </h4>
          </div>
          <div id="ac1" class="panel-collapse collapse in">
            <div class="panel-body">
              <p>
                Esta herramienta te ayudará a visualizar el monto que debe
                pagarse una vez terminada la relación laboral.
              </p>
              <p>
                Ya sea que la relación de trabajo haya terminado por despido,
                recisión del contrato o alguna otra causa, es importante conocer
                el monto que debe pagarse al trabajador para concluir la
                relación laboral.
              </p>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              <a data-toggle="collapse" data-parent="#accordion" href="#ac2">
                ¿Como funciona la calculadora?
              </a>
            </h4>
          </div>
          <div id="ac2" class="panel-collapse collapse">
            <div class="panel-body">
              <p>
                En Enfoque Laboral desarrollamos esta calculadora de finiquito,
                como instrumento de referencia que te permitirá obtener un
                cálculo “aproximado” del monto que correspondería
              </p>
              <p>
                al trabajador en caso de una terminación laboral por cualquiera
                de las causas enumeradas en la Ley Federal del Trabajo.
              </p>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              <a data-toggle="collapse" data-parent="#accordion" href="#ac3">
                ¿Por qué se creó Enfoque Laboral?
              </a>
            </h4>
          </div>
          <div id="ac3" class="panel-collapse collapse">
            <div class="panel-body">
              <p>
                Este sitio web forma parte de un proyecto muy meditado que nace
                del entusiasmo por ayudar a impulsar las correctas prácticas
                laborales por parte de las empresas y de los trabajadores,
              </p>
              <p>
                así como el deseo de informar a los usuarios acerca de asuntos
                relacionados con el Derecho Laboral. En México existe una gran
                desinformación en relación a cualquier tipo de conflicto laboral
                y
              </p>
              <p>
                derechos de los empleados, por eso queremos conectar a las
                empresas y trabajadores con expertos jurídicos en la materia con
                el objetivo de aclarar todas las dudas existentes de manera
                rápida, concreta y gratuita.
              </p>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              <a data-toggle="collapse" data-parent="#accordion" href="#ac4">
                ¿Qué tipo de consultas puedo realizar si soy usuario?
              </a>
            </h4>
          </div>
          <div id="ac4" class="panel-collapse collapse">
            <div class="panel-body">
              <p>
                En nuestra sección de Consulta (clic aquí) puedes realizar
                cualquier tipo de pregunta relacionada con el Derecho Laboral
                que desees resolver, como los días de vacaciones
                correspondientes por año trabajado,
              </p>
              <p>
                los pasos a seguir si has sido despedido sin motivo de la
                empresa para la que trabajabas, cómo conseguir el permiso de
                trabajo en México si eres extranjero, entre otras cuestiones.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Accordion -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>