<template>
  <div class="carousel-services-area bg-gray">
    <div class="container-box oh">
      <div class="carousel-service-items owl-carousel owl-theme">
        <div class="item" style="position: relative">
          <div class="row">
            <div class="col-md-6 info" align="center">
              <h5 class="st-subtitle">Enfoque Laboral</h5>
              <h2 class="st-title">
                Confía en la experiencia<br />
                de los mejores especialistas<br />
                en <u>derecho laboral</u>
              </h2>
              <p style="color: white">
                Sabemos que tu tranquilidad es lo más importante en cualquier
                tipo de conflicto laboral. En Enfoque Laboral contamos con los
                mejores abogados y contadores del país, quienes tienen una
                amplia experiencia en la asesoría y defensa tanto de
                trabajadores como de empresas y dependencias gubernamentales.
              </p>
              <router-link
                :to="{ name: 'Us' }" style="color: white !important;"
                class="btn btn-theme effect btn-sm"
                >Nosotros</router-link
              >
            </div>
            <div class="col-md-6 bg-banner">
              <a @click="playVideo" id="play-video" class="video-play-button">
                <span></span>
              </a>
            </div>
          </div>
          <div v-show="showVideo" class="video-overlay-open">
            <a @click="closeVideo" class="close-video">&times;</a>
            <video width="1000" height="562" controls autoplay>
              <source
                src="../../assets/video/video_enfoque.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showVideo: false,
    };
  },

  methods: {
    playVideo() {
      this.showVideo = true;
    },
    close(e) {
      if (e.keyCode === 27) {
        this.closeVideo();
      }
    },
    closeVideo() {
      this.showVideo = false;
    },
  },
};
</script>

<style scoped>
.carousel-services-area .info {
  background-color: #4967AE !important;
}
.bg-banner {
  background-image: url("../../assets/img/banner/9.jpg") !important;
  background-size: cover;
}

.video-overlay-open {
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.close-video {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
  padding: 10px;
}

@media (max-width: 959px) {
  .bg-banner {
    height: 400px;
  }
}
</style>
