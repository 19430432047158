<template>
  <div class="works-rules-area default-padding">
    <div class="container">
      <div class="site-heading text-center">
        <div class="row justify-center">
          <div class="col-md-8">
            <h2 class="st-title">¿<u>Como</u> Funciona?</h2>
            <p>
              Somos un equipo de profesionales compuesto por los abogados y
              contadores más prestigiosos de México, quienes se encargan de
              resolver todas las dudas relacionadas con Derecho Laboral que
              cualquier usuario decida plantear.
            </p>
          </div>
        </div>
      </div>
      <div class="works-rules-items">
        <div class="row">
          <div class="col-md-3 col-sm-6">
            <div class="item">
              <div class="icon">
                <i class="fas fa-users"></i>
              </div>
              <div class="vertical-line">
                <span>1</span>
              </div>
              <div class="info">
                <h4>Consulta XP</h4>
                <p>
                  Con nuestro sistema podras plantear en una consulta express
                  dando a detalle la problematica que estas pasando.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="item">
              <div class="info">
                <h4>Expediente</h4>
                <p>
                  Podras visualizar un expediente personalizado sobre tu
                  consulta, agendando contacto con alguno de nuestros abogados.
                </p>
              </div>
              <div class="vertical-line bottom">
                <span>2</span>
              </div>
              <div class="icon icon-down">
                <i class="fas fa-file"></i>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="item">
              <div class="icon">
                <i class="fas fa-chart-pie"></i>
              </div>
              <div class="vertical-line">
                <span>3</span>
              </div>
              <div class="info">
                <h4>Retroalimentacion</h4>
                <p>
                  Basada en la platica de contacto, generamos retroalimentacion
                  en sistema generando un plan de accion a tu problematica.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="item">
              <div class="info">
                <h4>Solución Final</h4>
                <p>
                  En tu panel podras descargar documentacion requerida a tu
                  caso, con pasos y guia de nuestros asesosres hasta completar
                  la solucion.
                </p>
              </div>
              <div class="vertical-line bottom">
                <span>4</span>
              </div>
              <div class="icon icon-down">
                <i class="fas fa-chalkboard-teacher"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        { icon: "", title: "", text: "" },
        { icon: "", title: "", text: "" },
        { icon: "", title: "", text: "" },
        {
          icon: "fas fa-chalkboard-teacher",
          title: "Solución Final",
          text: "En tu panel podras descargar documentacion requerida a tu caso, con pasos y guia de nuestros asesosres hasta completar la solucion.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>