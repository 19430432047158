<template>
  <div class="banner-area">
    <div
      id="bootcarousel"
      class="carousel slide item-bg animate_text"
      data-ride="carousel"
    >
      <!-- Wrapper for slides -->
      <div class="carousel-inner text-uppercase text-dark carousel-zoom">
        <div class="item active">
          <div class="slider-thumb bg-fixed bg-banner-one"></div>
          <div class="box-table">
            <div class="box-cell">
              <div class="container">
                <div class="row">
                  <div class="col-md-7">
                    <div class="content" data-animation="animated slideInRight" style="margin-right: 100px; background: rgba(73, 103, 174, .4); padding: 10px" align="center">
                      <h1 style="text-align: center; color: white">
                        Somos <br />
                        Los Mejores En<br />
                        Derecho Laboral
                      </h1>
                      <router-link
                        :to="{ name: 'Calculator' }"
                        class="btn btn-theme effect standard btn-md"
                        >Calculadora Finiquito</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="slider-thumb bg-fixed bg-banner-two"></div>
          <div class="box-table">
            <div class="box-cell">
              <div class="container">
                <div class="row">
                  <div class="col-md-7">
                    <div class="content" data-animation="animated slideInRight" style="margin-right: 100px;background: rgba(73, 103, 174, .4); padding: 10px" align="center">
                      <h1 style="text-align: center; color: white">
                        Brinda<br />
                        Beneficios <br>
                        A tus <br>
                        Empleados
                      </h1>
                      <a class="btn btn-theme effect standard btn-md" href="/servicio/asesoria-express"
                        >Planes Empresariales</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Wrapper for slides -->

      <!-- Left and right controls -->
      <a class="left carousel-control" href="#bootcarousel" data-slide="prev">
        <i class="fa fa-angle-left"></i>
        <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control" href="#bootcarousel" data-slide="next">
        <i class="fa fa-angle-right"></i>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.bg-banner-one {
  background-image: url("../../assets/img/banner/banner1.png");
}
.bg-banner-two {
  background-image: url("../../assets/img/banner/banner2.png");
}

.banner-area {
  height: 100vh !important;
}

.fa-angle-right,
.fa-angle-left {
  color: #FAB848 !important;
}
</style>
