import { render, staticRenderFns } from "./ListQuestion.vue?vue&type=template&id=40a1c25e&scoped=true&"
import script from "./ListQuestion.vue?vue&type=script&lang=js&"
export * from "./ListQuestion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40a1c25e",
  null
  
)

export default component.exports